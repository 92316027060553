<template>
  <div>
    <div class="load-top-footer">
      <div class="logo-footer">
        <div class="footer_left">
          <img
            @click="gohomePage"
            class="img"
            src="/img/m-gift-pack.gif"
            alt=""
          />
          <img
            @click="gohomePage"
            class="img_text"
            src="/img/home-describe-banner.png"
            alt=""
          />
        </div>
        <img @click="gohomePage" class="btn" src="/img/new_home28.svg" />
      </div>
    </div>
    <div class="about" v-if="false">
      <div class="about_content">
        <template v-if="false">
          <div class="text">
            {{
              "昆明优浩网络科技有限公司是一家专注于网络技术与人工智能的高科技公司，我们致力于为用户提供最新的SEO技术与工具。"
            }}
          </div>
          <div class="concat">
            <span>联系方式：</span><span> 19915680178（唐先生）</span>
          </div>
          <div class="concat">
            <span>客服邮箱：</span><span> aiseoservicer@gmail.com</span>
          </div>
        </template>
        <div class="concat law">
          <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            class="foot_fill_text"
            >备案号 : 滇ICP备2024017174号-19</a
          >
        </div>
      </div>
    </div>
    <div style="height: 90px"></div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  name: "Footer",
  props: {
    ishome: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    QRCode,
  },
  data() {
    return {};
  },
  methods: {
    gohomePage() {
      let ext = this.$cookies.get("aiseo_channel_ext");
      if (ext) {
        window.open("https://www.aiseo.net?ext=" + ext);
      } else {
        window.open("https://www.aiseo.net");
      }
    },
  },
};
</script>
