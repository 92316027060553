import Vue from 'vue'
import VueRouter from 'vue-router'
// 导入组件库
import Home from '../views/Home.vue'
import Mobile from '../views/Mobile.vue'

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function (location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/mobile',
    component: Mobile
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
