/* eslint-disable */
export default{
  //复制文本
  copyText: function (text) {
    let element = createCopyElement(text);
    element.select();
    element.setSelectionRange(0, element.value.length);
    element.focus();
    document.execCommand('copy');
    element.remove();
    //alert("已复制到剪切板");
  }
}

//创建临时的输入框元素
function createCopyElement(text) {
  let isRTL = document.documentElement.getAttribute('dir') === 'rtl';
  let element = document.createElement('input');
  // 防止在ios中产生缩放效果
  element.style.fontSize = '12pt';
  // 重置盒模型
  element.style.border = '0';
  element.style.padding = '0';
  element.style.margin = '0';
  // 将元素移到屏幕外
  element.style.position = 'absolute';
  //element.style[isRTL ? 'right' : 'left'] = '-9999px';
  // 移动元素到页面底部
  let yPosition = window.pageYOffset || document.documentElement.scrollTop;
  element.style.top = `${yPosition}px`;
  //设置元素只读
  element.setAttribute('readonly', '');
  // console.log(text);
  element.value = text;
  document.body.appendChild(element);
  return element;
}

