/* eslint-disable */
export default {
  getUrlParamByKey: function (name) {
    //抖音落地页特殊处理
    // if (name === "ext") {
    //   return decodeURIComponent("MTgtMA%3D%3D");
    // }
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
  }
}
