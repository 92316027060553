<template>
  <div>
    <!-- 定义路由来匹配要显示的组件 -->
    <router-view></router-view>
  </div>
</template>

<script>
import copy2clipboard from "@/utils/copy2clipboard";
import urlUtils from "@/utils/urlUtils";
// (function (w, d, s, q, i) {
//   w[q] = w[q] || [];
//   var f = d.getElementsByTagName(s)[0],
//     j = d.createElement(s);
//   j.async = true;
//   j.id = "beacon-aplus";
//   j.src = "https://d.alicdn.com/alilog/mlog/aplus/" + i + ".js";
//   f.parentNode.insertBefore(j, f);
// })(window, document, "script", "aplus_queue", "203467608");

// //集成应用的appKey
// aplus_queue.push({
//   action: "aplus.setMetaInfo",
//   arguments: ["appKey", "644a1b5fba6a5259c44472aa"],
// });

// aplus_queue.push({
//   action: "aplus.sendPV",
//   arguments: [{ is_auto: false }],
// });

export default {
  name: "App",

  data() {
    return {};
  },
  methods: {
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    copyExt() {
      let ext = urlUtils.getUrlParamByKey("ext") || "";
      this.$cookies.set("aiseo_channel_ext", ext, "1Y");
      copy2clipboard.copyText(ext);
    },
  },
  mounted() {
    if (this._isMobile()) {
      this.$router.replace("/mobile");
    } else {
      this.$router.replace("/home");
    }
  },
  created() {
    this.copyExt();
  },
};
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
</style>>
