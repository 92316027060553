import request from '../axios/axios.js'

export function getCustomerService(params) {
  return request({
    url: '/main/customer/service',
    method: 'get',
    params
  })
}

export function downloadUrl(data) {
  return request({
    url: '/main/v1/downloadUrl',
    method: 'post',
    data
  })
}


//分享app下载链接
export function v1Share(params) {
  return request({
    url: '/v1/share',
    method: 'get',
    params
  })
}

//代理商信息
export function channelInfo(params) {
  return request({
    url: '/channel/info',
    method: 'get',
    params
  })
}
//配置信息
export function configInfo(parameter) {
  return request({
    url: '/admin/config/info',
    method: 'get',
    params: parameter
  })
}

